$(function () {
    $('.owl-carousel-manifestation').owlCarousel({
        loop:true,
        margin:10,
        dots:true,
        autoplay:true,
        autoplayTimeout:3000,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    })
})


$(function () {
    $('.owl-carousel-home').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        items:1,
        autoplay:true,
        autoplayTimeout:3000,
        dots:true,
    })
})

