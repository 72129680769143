$(document).ready(function () {
    const Viewport = fwd.import('Viewport')
    const $window = $(window)
    const $body = $(document.body)
    const $nav = $('#main-nav')
    const $mainMenu = $('#main-menu')
    const $mainMenuItems = $mainMenu.children('li').not('.current-node')
    const $currentMainMenuItem = $mainMenu.children('.current-node')
    const $trigger = $('#mobile-nav-trigger')


    $.each($mainMenuItems, (_, item) => {
        let closeTimer
        const $matchingSubmenu = $('#sub-' + item.id)
        const $item = $(item)

        if ($item.hasClass('parent')) $item
            .on('mouseover.submenu', () => {
                if (Viewport.isDesktop()) {
                    if (closeTimer) clearTimeout(closeTimer)

                    $item.addClass('hovered')
                    $currentMainMenuItem.removeClass('current-node')
                    $matchingSubmenu
                        .addClass('visible')
                        .slideDown(200)
                }

            })
            .on('mouseleave.submenu', () => {

                if (Viewport.isDesktop()) {
                    function closeSubmenu() {
                        $item.removeClass('hovered')
                        $currentMainMenuItem.addClass('current-node')
                        $matchingSubmenu
                            .off('mouseover.submenu mouseleave.submenu')
                            .removeClass('visible')
                            .slideUp(200)
                    }

                    closeTimer = setTimeout(() => {
                        closeSubmenu()
                    }, 400)

                    $matchingSubmenu
                        .on('mouseover.submenu', () => {
                            clearTimeout(closeTimer)
                        })
                        .on('mouseleave.submenu', () => {
                            closeTimer = setTimeout(() => {
                                closeSubmenu()
                            }, 400)
                        })
                }
            })
    })

    $trigger.on('click.mobileNav', () => {
        $nav.fadeToggle(300)
        $body.toggleClass('no-scroll')
        $trigger.toggleClass('active')
        if ($nav.is(':visible')){
            $body.height($window.height());
        }else{
            $body.css('height', 'auto');
        }

    })


    $window.on('resize.mobileNav', () => {
        if (!Viewport.isMobile()) {
            if ($nav.attr('style'))
                $nav.removeAttr('style')

            if ($body.hasClass('no-scroll'))
                $body.removeClass('no-scroll')

            if ($trigger.hasClass('active'))
                $trigger.removeClass('active')
        }
    })
})
