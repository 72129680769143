$(document).ready(function () {
    $('.fwd-fancybox').fancybox({
        padding: 0,
        helpers:  {
            thumbs : {
                width: 150,
                height: 150
            }
        }
    })
});
