$(document).ready(function () {
    const $social = $('#social-networks').find('a')
    const icons = {
        facebook: 'facebook.png',
        twitter: 'twitter.png',
        pinterest: 'pinterest.png',
        youtube: 'youtube.png',
        instagram: 'instagram.png',
        google: 'googleplus.png',
        tumblr: 'tumblr.png'
    }
    // TODO: fallback image

    $.each($social, (_, link) => {
        for (let icon in icons) if (icons.hasOwnProperty(icon)) {
            if (link.href && link.href.match(icon)) {
                let img = document.createElement('img')
                let imgName = icons[icon]
                img.src = `/site_media/static/img/${imgName}`
                img.alt = icon[0].toUpperCase() + icon.substring(1)
                let $img = $(img)
                let $link = $(link)
                $link.prepend($img)
            }
        }
    })
})
