const minorBreakpoint = 640  // the same as in vars.less (px equivalent of rem)
const majorBreakpoint = 960  // the same as in vars.less (px equivalent of rem)
const $window = $(window)

class Viewport {
    static isMobile() {
        return $window.width() < minorBreakpoint
    }

    static isTablet() {
        return majorBreakpoint > $window.width() >= minorBreakpoint
    }

    static isDesktop() {
        return $window.width() > majorBreakpoint
    }
}

fwd.export('Viewport', Viewport)
