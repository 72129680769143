$(document).ready(function () {
    const $stripe = $('#home-stripe')
    const $toggle = $('#home-stripe-toggle')
    const $label = $('#home-stripe-label')

    $label.hide()

    $toggle.on('click.expandStripe', () => {
        $stripe.toggleClass('expanded')
        $label.toggle()
    })
})
